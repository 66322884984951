import React from 'react'


const Jquery = ()=>{
    return(
        <div>
<svg width="70px" height="40px" viewBox="0 0 512 116" >
    <defs></defs>
    <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g>
            <path d="M489.718192,8.64613026 L511.13882,8.76383142 C511.13882,8.76383142 471.313656,62.7278468 468.06216,67.1259464 C467.005794,68.5501302 465.401134,70.0861302 464.588016,71.9899464 C462.531188,76.7764598 460.419432,87.2754022 460.419432,87.2754022 L440.82023,87.3725058 C440.82023,87.3725058 443.86967,76.4292414 443.744122,70.600092 C443.633288,65.5075556 441.034054,60.421885 439.57554,55.314636 C438.102314,50.1455938 436.101394,40.02918 436.101394,40.02918 L456.25085,40.02918 L459.029578,51.1460536 L489.718192,8.64613026" fill="#1A1918"></path>
            <path d="M449.157394,20.0268506 C453.110192,22.4102988 455.535816,35.5555556 455.535816,35.5555556 C455.535816,35.5555556 434.8518,35.8605978 423.595648,35.8605978 L413.868628,35.8605978 L402.057318,87.2754022 L383.993134,87.2754022 C383.993134,87.2754022 395.234574,31.400705 397.888736,21.2705594 C398.749916,17.9916015 403.78066,18.5536245 412.277702,18.5536245 L425.846682,18.5536245 C434.608552,18.5536245 444.849532,17.4354636 449.157394,20.0268506" fill="#1A1918"></path>
            <path d="M365.059924,45.0667892 C365.059924,45.0667892 365.622928,41.7662528 363.149242,37.9448888 C361.037486,34.6865288 359.08561,33.929318 353.527172,33.7557088 C337.831724,33.2623448 338.136766,44.89318 338.136766,44.89318 L365.059924,45.0667892 L365.059924,45.0667892 Z M382.082452,31.7479234 C384.382528,40.0438928 379.99816,59.5391264 379.99816,59.5391264 C379.99816,59.5391264 357.104306,59.1565978 344.56423,59.5391264 C340.66538,59.6578084 335.997548,59.1154022 334.83721,60.9289808 C333.204108,63.4713256 335.433564,67.383908 337.323648,69.0366284 C339.499158,70.9404444 343.736398,71.1562298 345.258666,71.3504368 C355.436874,72.6431878 376.7398,71.6839234 376.7398,71.6839234 L373.04987,88.0247662 C373.04987,88.0247662 342.94584,89.5333026 327.88892,85.940475 C324.915984,85.2323066 322.12941,83.8012568 320.246192,81.7718928 C319.73223,81.2167356 319.286928,80.459525 318.80043,79.8062836 C318.412016,79.284475 317.280122,77.5689808 316.772046,76.213456 C313.33321,67.0494406 317.175172,49.4923524 320.940628,40.0850882 C321.378084,38.9943908 321.927356,37.6123832 322.505072,36.4373334 C323.226974,34.971954 323.838038,33.3182528 324.414774,32.4423602 C324.803188,31.838161 325.2838,31.4487662 325.686928,30.8788966 C326.151846,30.2050574 326.693272,29.551816 327.194482,28.9691954 C328.583356,27.3566896 330.160552,26.2169502 332.057502,24.800613 C338.330974,20.1033564 349.68423,16.4556015 363.32285,17.8523218 C368.457564,18.3800153 376.212108,20.2141916 379.99816,25.4950498 C381.012352,26.905502 381.512582,29.7048276 382.082452,31.7479234 L382.082452,31.7479234 L382.082452,31.7479234 Z" fill="#1A1918"></path>
            <path d="M299.22869,18.4908506 L316.744582,18.3868812 C316.744582,18.3868812 306.983234,64.6944368 302.008398,87.2754022 L281.858942,87.2754022 C267.776,87.2754022 255.234942,89.019341 249.899158,78.9372568 C244.72325,69.1484444 249.662774,53.0498698 251.983448,42.1134712 C253.623418,34.3736398 257.076966,18.5536245 257.076966,18.5536245 L275.224522,18.3868812 C275.224522,18.3868812 270.096674,43.0168276 267.96334,55.314636 C267.199264,59.7196016 265.62305,63.8675862 267.268904,67.1259464 C268.832368,70.22541 271.020628,70.4470804 277.69036,70.600092 C279.803096,70.649134 286.722942,70.600092 286.722942,70.600092 L299.22869,18.4908506" fill="#1A1918"></path>
            <path d="M217.243954,51.8404904 C219.07813,45.3237702 221.752888,38.2577778 221.412536,32.3864522 C221.04472,26.106115 215.778574,20.3878008 212.380934,19.1862682 C202.14682,15.5728429 192.565946,20.624184 188.758314,24.7437242 C183.83154,30.0804904 182.164107,36.3127662 180.420169,44.89318 C179.121533,51.2637548 178.058299,57.9893946 180.420169,62.957364 C184.602483,71.7398314 197.64377,69.8222836 211.685518,69.9056552 C213.769808,64.3197548 215.49315,58.0453026 217.243954,51.8404904 L217.243954,51.8404904 L217.243954,51.8404904 Z M241.561992,26.1335786 C243.562912,40.6824214 235.885854,56.7398008 230.59813,70.7040614 C235.989824,71.1905594 240.714544,70.6285364 241.054896,71.030682 C241.40898,71.5524904 237.441472,87.6991264 236.058482,87.7403218 C231.779066,87.852138 217.59902,87.969839 211.685518,87.969839 C192.391356,87.969839 174.340904,89.2272796 165.135693,79.6326744 C161.397701,75.7348046 158.81318,69.0503602 158.187402,64.3472184 C157.144766,56.4955708 158.451249,47.8523832 160.271693,40.7245978 C161.918529,34.2902682 164.030284,28.2178698 167.219985,22.659433 C175.327632,8.50685058 189.432153,-1.99797701 213.075372,0.426666666 C217.660812,0.89943295 224.247172,2.9072184 228.360828,5.28968582 C228.645272,5.45740996 229.639846,6.2489502 229.868384,6.38822988 C235.42682,9.92514942 240.491892,18.3662835 241.561992,26.1335786 L241.561992,26.1335786 L241.561992,26.1335786 Z" fill="#1A1918"></path>
            <path d="M156.193349,18.5742222 C155.026146,23.88941 153.858943,29.9137472 152.628966,35.166161 L133.174927,35.166161 C134.682483,29.3438774 135.835954,23.5559234 137.34351,18.4908506 C143.56892,18.4908506 149.356874,18.5742222 156.193349,18.5742222" fill="#1A1918"></path>
            <path d="M132.48049,40.02918 L151.239111,40.02918 C148.787004,53.6060076 143.930851,75.5052874 140.123218,90.748567 C137.73977,100.288245 136.148843,107.250268 129.700782,112.286897 C129.124046,112.739065 128.79154,112.524261 128.311908,112.982314 C127.123126,114.115188 121.627464,115.428536 118.584889,115.761042 C113.637517,116.296582 108.044751,115.830682 101.965487,115.706115 C103.556414,109.890697 105.356261,102.908077 106.773579,97.6968582 C121.593134,99.489839 121.968797,85.4824214 124.837762,71.9899464 C126.49833,64.1942068 131.529073,43.1561072 132.48049,40.02918" fill="#1A1918"></path>
            <g transform="translate(0.000000, 8.827586)" fill="#21609B">
                <path d="M88.7084138,66.6365058 L88.3062682,67.678161 C81.4550804,81.0176246 69.3171494,89.2027586 52.0033104,89.5637088 C43.9093946,89.7304522 36.1970268,87.6540076 31.1594176,85.3951264 C20.286774,80.5105288 11.1423755,71.4514636 6.14792338,61.7725058 C-1.01615326,47.897502 -2.30792338,28.596475 12.5881379,14.180046 C12.8176552,14.180046 10.7814253,17.3059923 11.0109425,17.3059923 L10.6009502,18.6399387 C-0.133394636,52.2465594 32.9376858,81.046069 65.0857932,77.7523984 C72.8118928,76.9608582 82.6644598,72.729502 88.7084138,66.6365058"></path>
                <path d="M69.2543754,52.7399234 C77.3424062,52.7958314 85.7904062,49.0156628 90.792705,43.7083218 C86.416184,54.8183296 73.7436934,61.9598468 56.7486284,59.6882146 C42.5195402,57.7843984 28.9642912,43.4650728 27.5675708,29.1173026 C26.5465134,18.675249 30.1864214,12.4429732 36.183295,4.88361686 C34.0990038,8.83739464 33.3202146,11.8868352 33.1260076,13.8318467 C30.8475096,36.4196782 51.1695938,52.6222222 69.2543754,52.7399234"></path>
                <path d="M89.980567,24.6848736 C88.7231264,27.5685518 81.483525,33.0151724 78.2869578,33.6958774 C65.6281992,36.3922146 57.5205518,30.389456 53.2744828,23.558866 C52.64282,22.5378084 51.4756168,19.7522146 51.2666974,19.0430651 C49.619862,13.443433 50.3770728,4.9738544 55.290115,0.58948659 C53.810023,4.75806896 53.5942376,9.60049042 54.3730268,12.7480153 C54.8448122,14.6518314 56.282728,17.9739464 57.7216246,20.0641226 C60.340475,23.8717548 62.0010422,24.7966896 65.3643524,26.8182068 C66.878774,27.7284292 68.5040306,28.4581762 69.2063142,28.659249 C72.3950344,29.5969348 79.1275402,32.0706206 89.980567,24.6848736"></path>
            </g>
        </g>
    </g>
</svg>


        </div>
    )

}

export {Jquery}